html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;
}

html:root {
  --swiper-theme-color: #048CDD
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  margin: 0;
  padding: 0;
}

.ReactModal__Overlay {
  z-index: 200;
}

@supports (-webkit-overflow-scrolling: touch) {
  input,
  textarea {
    font-size: 16px !important;
  }
}
